import { Container, Divider, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import React, { FC } from 'react';
import ZFooter from '../components/ZFooter';
import ZHeading from '../components/ZHeading';
import ZSEO from '../components/ZSeo';
import Img from 'gatsby-image';

const ServicesItem = ({ item }: { item: string }) => {
  const classes = useStyles();
  return (
    <Typography
      onClick={() => {
        navigate('/contact-us');
      }}
      className={classes.servicesItem}
    >
      {item}
    </Typography>
  );
};

const PageServices: FC = () => {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query {
      allImageSharp {
        nodes {
          fluid(maxWidth: 1600) {
            originalName
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const servicesIcon1 = data.allImageSharp.nodes.find((n) => n.fluid.originalName === 'servicesIcon1.png').fluid;
  const servicesIcon2 = data.allImageSharp.nodes.find((n) => n.fluid.originalName === 'servicesIcon2.png').fluid;
  const servicesIcon3 = data.allImageSharp.nodes.find((n) => n.fluid.originalName === 'servicesIcon3.png').fluid;
  const servicesIcon4 = data.allImageSharp.nodes.find((n) => n.fluid.originalName === 'servicesIcon4.png').fluid;

  return (
    <>
      <ZSEO title='Meet The Team' />
      <ZHeading firstText={'OUR'} secondText={'Services'} />
      <Container className={classes.container} maxWidth='lg'>
        <Grid container>
          <Grid container className={classes.servicesWrapper} item xs={12}>
            <Grid container justify='center' item md={2} xs={12}>
              <Img className={classes.image} fluid={servicesIcon1} />
            </Grid>
            <Grid item md={10} xs={12}>
              <Typography className={classes.servicesHeading}>CONTENT AND COPYWRITING</Typography>
              <Typography className={classes.servicesBody}>
                Copywriting is the practice of crafting written text in order to inform, inspire or persuade. In most cases, copywriting is used to increase sales and conversions.
                Mediums where copywriting is implemented include sales letters, blog posts, advertisements and social media posts, webcopy, scripts and more.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider className={classes.divider} />
            </Grid>
          </Grid>
          <Grid container className={classes.servicesWrapper} item xs={12}>
            <Grid container justify='center' item md={2} xs={12}>
              <Img className={classes.image} fluid={servicesIcon2} />
            </Grid>
            <Grid item md={10} xs={12}>
              <Typography className={classes.servicesHeading}>CONTENT MARKETING</Typography>
              <Typography className={classes.servicesBody}>
                Marketing Matters, and marketing will always matter. The Write Step is committed to providing content that supports your business and your brand in being the
                ‘purple cow’ - the cow that gets noticed, the exceptional cow that refuses to blend in with the herd.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider className={classes.divider} />
            </Grid>
          </Grid>

          <Grid container className={classes.servicesWrapper} item xs={12}>
            <Grid container justify='center' item md={2} xs={12}>
              <Img className={classes.image} fluid={servicesIcon3} />
            </Grid>
            <Grid item md={10} xs={12}>
              <Typography className={classes.servicesHeading}>RESUME WRITING</Typography>
              <Typography className={classes.servicesBody}>
                Your resume isn't just a piece of paper with your professional experience, it's a marketing document that represents you, and since the average employer spends
                under 30 seconds reviewing a resume, it's important to stand out. Let us help you document your uniqueness!
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider className={classes.divider} />
            </Grid>
          </Grid>

          <Grid container className={classes.servicesWrapper} item xs={12}>
            <Grid container justify='center' item md={2} xs={12}>
              <Img className={classes.image} fluid={servicesIcon4} />
            </Grid>
            <Grid item md={10} xs={12}>
              <Typography className={classes.servicesHeading}>PROOFREADING</Typography>
              <Typography className={classes.servicesBody}>
                Sometimes all it takes is an extra pair of eyes, with the right tools to set your document apart. We check for: Grammatical errors, Mechanical consistency: syntax,
                spelling, capitalization, punctuation and citation errors and Fact accuracy (historical dates, statistical data, etc)
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider className={classes.divider} />
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <ZFooter />
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: 0
  },
  divider: {
    marginTop: 20,
    [theme.breakpoints.down('md')]: {
      marginTop: 40
    }
  },
  headingWrapper: {
    color: '#000',
    height: 160,
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    marginBottom: 40
  },
  headingTextFirst: {
    fontSize: 30,
    fontWeight: 400,
    marginRight: 8
  },
  servicesWrapper: {
    paddingLeft: 20,
    paddingRight: 20,
    marginBottom: 50
  },
  servicesHeading: {
    color: '#BE3CA4',
    fontSize: 18,
    fontWeight: 700,
    marginBottom: 10
  },
  servicesBody: {},
  servicesItem: {
    cursor: 'pointer'
  },
  image: {
    height: 130,
    width: 130,
    marginBottom: 20,
    borderRadius: 5,
    [theme.breakpoints.down('md')]: {
      marginTop: -20
    }
  }
}));

export default PageServices;
